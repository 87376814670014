import React  from 'react';
import Field from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import withPreload from "./Preload";


const Autocomplete = withPreload((props) =>{
  let { id, helperText, valid, error, input } = props;
  let { classes, renderOption, controlProps } = props;

  let helperTextId = helperText && id ? `${id}-helper-text`: `select-${Math.floor(Math.random() * 1000000001)}`;

  return (<Field
    {...{
      ...input, classes, error: !valid, helperText: error ?  error: null, id: helperTextId,
      renderOption: renderOption || (({ label, value }) => (<>{label} ({value})</>)),
      renderInput: (params) => (<TextField {...params} {...controlProps}  />)
    }}
  />)
})


Autocomplete.defaultProps = {
  meta: {},
  freeSolo: true,
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
  required: false,
  type: 'text',
  autoComplete:'off',
  size:'small'
};

export default Autocomplete;


