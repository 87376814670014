import React, { useEffect, useState } from 'react';
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { getConfig } from "@gqlapp/base/utils/RenderBase";


let timeout = null;
export default  compose(
  withProps(getConfig),
	withProps((props: any)=>{

		let { placeholder, autoComplete, label, type, className, } = props;
		let { required, variant, margin, fullWidth, input, onChange, options: opts, loadOptions } = props
		let { size, shrink, selectProps, onFocus, onOpen, freeSolo, renderOption, disabled } = props

		let { noOptionsText, filterSelectedOptions, includeInputInList, filterOptions, getOptionLabel } = props
		let { value, InputLabelProps, InputProps }: any = input;

		if (value) {
			// InputLabelProps = { ...InputLabelProps }
			selectProps = Object.assign({}, selectProps, { defaultValue: value });
		}

		getOptionLabel = getOptionLabel || ((opt: any) => typeof opt === 'string' ? opt : opt?.value);

		let [options, setOptions] = React.useState(opts || []);
		let [inputValue, setInputValue] = React.useState(null);

		useEffect(function (){
			let active = true;
			new Promise(async resolve => {
				if (!loadOptions) return;
				resolve(await loadOptions(inputValue, active));
			}).then(setOptions).catch(()=> null);

			return () => { active = false; }
		}, [loadOptions, inputValue])

		function onInputChange(_: any, newInputValue: string) {
			clearTimeout(timeout);
			timeout = setTimeout(()=> setInputValue(newInputValue), 800);
		}

		return {
			InputProps,
			controlProps: {
				placeholder: placeholder || label || '', disabled,
				label, variant, margin, fullWidth, required, className,
				type, InputLabelProps, size: size || 'small', shrink,
			},
			input:{
				...selectProps, disabled, autoComplete, freeSolo, options: options || [], onOpen, onFocus, onChange, onInputChange, noOptionsText,
				filterSelectedOptions, includeInputInList, filterOptions, getOptionLabel, renderOption,
			}
		}
	})
);
