import React from 'react';
import {withFormik} from "formik";
import { fieldConfig } from "@xem/custom-page-client-react/components/form/blocks/Geo/content/Content.Utils";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import Autocomplete from "@gqlapp/base/field/Autocomplete";
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import Grid from '@mui/material/Grid';
import { MapPin } from 'lucide-react';
import compose from "recompose/compose";
import { withContentCallback } from "@xem/entity/classes/preloadContents";
import SEARCH_MAP_QUERY from "@xem/entity/graphql/MapSearch.graphql";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyA-BkZjDHm7M92j4446rnH6lR_ogwm-9q8';

function loadScript(src: string, position: HTMLElement | null, id: string) {
	if (!position) {
		return;
	}

	const script = document.createElement('script');
	script.setAttribute('async', '');
	script.setAttribute('id', id);
	script.src = src;
	position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
	offset: number;
	length: number;
}
interface StructuredFormatting {
	main_text: string;
	secondary_text: string;
	main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
	description: string;
	structured_formatting: StructuredFormatting;
}


// @ts-ignore
const CountrySearchForm = ({ mapSearch, setFieldValue, values: { address  } = {}, ...props}) =>{

	let options = []
	//
	// const [inputValue, setInputValue] = React.useState('');
	// const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
	const loaded = React.useRef(false);

	if (typeof window !== 'undefined' && !loaded.current) {
		if (!document.querySelector('#google-maps')) {
			loadScript(
				`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
				document.querySelector('head'),
				'google-maps',
			);
		}

		loaded.current = true;
	}

	const fetch = React.useMemo(
		() =>
			debounce(
				(
					request: { input: string },
					callback: (results?: readonly PlaceType[]) => void,
				) => {
					(autocompleteService.current as any).getPlacePredictions(
						request,
						callback,
					);
				},
				400,
			),
		[],
	);

	function search(value: string, active: boolean){
		return new Promise(async resolve => {
			if (!autocompleteService.current && (window as any).google) {
				autocompleteService.current = new (
					window as any
				).google.maps.places.AutocompleteService();
			}

			if (!autocompleteService.current) {
				resolve( undefined);
			}

			if (value === '') {
				resolve( undefined);
			}

			return fetch({input: value}, (results?: readonly PlaceType[]) => {
				if (active) {
					let newOptions: readonly PlaceType[] = [];

					if (address) {
						newOptions = [address];
					}

					if (results) {
						newOptions = [...newOptions, ...results];
					}

					resolve(newOptions);
				}
			});
		})
	}


	return (<>

		<Field
			filterSelectedOptions
			autoComplete
			includeInputInList
			filterOptions={(x: any) => x}
			getOptionLabel={(option: any) =>
				typeof option === 'string' ? option : option.description
			}
			{...fieldConfig({
				component: Autocomplete,
				name: `address`, value: address,
				label: `page:block.geo.address.label`.t(),
				placeholder: `page:block.geo.address.placeholder`.t(),
				loadOptions: search,
				onChange: ({}: any, newValue: PlaceType | null) => {
					setFieldValue('address', newValue);
				}
		})}

			renderOption={(props: any, option: any) => {
				const { key, ...optionProps } = props;
				return (
					<li key={key} {...optionProps}>
						<Grid container sx={{ alignItems: 'center' }}>
							<Grid  sx={{ width: '44px', wordWrap: 'break-word' }}><MapPin /></Grid>
							<Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
								<Typography variant="body2" sx={{ color: 'text.secondary' }}>
									{option.description}
								</Typography>
							</Grid>
						</Grid>
					</li>)}
			}

		/>

	</>);
}




const handleSubmit = async ({}, {}) => {}

const composeWithFormik = withFormik({
	mapPropsToValues: ({ }) => ({ search: '' }),
	handleSubmit,
	enableReinitialize: true,
	displayName: 'CountrtSearchForm', // helps with React DevTools
});

// @ts-ignore

export default compose(
	withContentCallback(((res: any)=> res), 'mapSearch', SEARCH_MAP_QUERY),
	composeWithFormik
	// @ts-ignore
)(CountrySearchForm);
