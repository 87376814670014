import compose from "recompose/compose";
import RenderField from "@xem/look/Renders/Field";
import apps from '../apps'
import withProps from "recompose/withProps";
import uuid from "@gqlapp/base/utils/uuid";
import {useState} from "react";
/**
 *
 * @param props
 */
export function fieldConfig (props: any) {
	let { setFieldValue, tabIndex, name, value, inputProps, ...rest }: any = props
	tabIndex = tabIndex || 1;
	return {
		...{ onRemove: setFieldValue },
		...{ component: RenderField, inputProps: { ...inputProps, tabIndex } },
		...{ name, value },
		...rest
	}
}

let timeout = null;
export default compose(
	apps,
	// withProps(({ onActions, setFieldValue }: any)=>({
	// 	openPopup: function openPopup(name: string, values){
	// 		return function (){
	// 			if(typeof onActions[`map`] !=='undefined'){
	// 				func(onActions[`map`])({
	// 					values,
	// 					onChange: function (values: any, { onClose }){
	// 						setFieldValue(name, values)
	// 						func(onClose)();
	// 					}
	// 				})
	// 			}
	// 		}
	// 	}
	// })),

	withProps(({ items }: any)=>{
		const [ edit, setEditable] = useState(-1);
		return { edit, setEditable }
	}),
	withProps(({ values, setFieldValue, setEditable })=>{

		let items = values?.items || [];

		function addApp({}){
			let item = { id: uuid(), items: [{ price: null, distance: null }] };
			items.push(item);
			setFieldValue('items', items);
			setEditable(items.length - 1)
		}


		/**
		 *
		 * @param id
		 */
		function onDelete(id: string){
			return (function onDelete(){
				let res = [...items].filter(({ id: i })=> i !== id);
				setFieldValue('items', res);
			})
		}

		return { items, addApp, onDelete }
	}),
	withProps(({ edit, items, setEditable }: any)=>({
			editable: function (idx: number){
				return function editable(){
					setEditable((edit !== idx) ? idx: -1);
				}
			},
			/*** Data convert */
			datasource: items.map(({ weight, type, id, image, ...values }: any, index: number)=>{
				image = image|| 'https://cdn.xem.link/public/icons/photo.svg';

				Object.assign(values, { id, type, weight, image })
				return ({ weight, data: { values, id, type, index } })
			})
		}
	))
)