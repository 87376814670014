import React from 'react';
import ClientModule from "@gqlapp/module-client-react";
import Route from "@xem/router/classes/Route";
import Test from './components/TestAuto';

export default new ClientModule({
  route:{
    Test: <Route component={Test} runtimejs  {...{ webType: 'gen', style:{ '--page-header-height': '0px !important' }, classNameOnPage: 'web',  className: null }} path={'/test'}  />,
  },
});
