import React from "react";
import withProps from "recompose/withProps";
import isEmpty from "@gqlapp/base/utils/isEmpty";
import Popup from "@gqlapp/base/Modal/Popup";

/**
 * saveCallback
 * @param values
 */
export function saveCallback(values: any){
	Object.keys(values).map((key)=>{
		if(isEmpty(values[key])){ delete values[key]; }
	});
	return values;
}


type Props = {
	dataRenders: any;
	blockRenders: any;
	blockActions: any[];
}

/**
 * indexCallback
 * @param ref
 * @param DataForm
 * @param icon
 * @param type
 * @param text
 */
export function indexCallback(ref: any, DataForm: any, icon: any, type: string, text: string){
	return withProps((props: any & Props) => {
		let dataRenders = props?.dataRenders || {}
		let blockActions = props?.blockActions || []

		blockActions.push({
			icon, text, callback: (rest: any) => {
				// @ts-ignore
				try { ref.current.open({ ...props, ...rest, saveCallback }); } catch (e) {}
			}
		})

		dataRenders[type] = (rest: any) => (<Popup {...{ ...props, ...rest, ref }} component={DataForm} />)

		return { dataRenders, blockActions }
	})
}


/**
 *
 * @param ref
 * @param blockType
 * @param Render
 */
// @ts-ignore
export default function renderCallback(ref?: any, blockType: string, Render: any) {
	return withProps((props: any) => {
		let blockRenders = props?.blockRenders || {}
		blockRenders[blockType] = function callback({ data: values, key: keyID, ...res }: any, toolProps: any = null, WrappedComponent?: any){
			values = saveCallback(values);
			let obj = { ...props, ...res, saveCallback, keyID, view: !ref, WrappedComponent, values }

			if(ref){
				Object.assign(obj, {
					onClick: (e: Event, rest: any)=>{
						e.preventDefault();
						try { ref.current.open({ ...props, ...rest, ...res, saveCallback, keyID, toolProps, blockType });} catch (e) {}
					}
				})
			}
			return ((rest: any) => React.createElement(Render, rest))(obj)
		}

		return ({ blockRenders })
	});
}